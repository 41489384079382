.border-table-bot {
  border: 1px solid #7186a0;
}
.action-table-bot {
  width: 250px;
  min-width: 250px;
}
#loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loading-spinner {
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-top-color: #fff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s ease-in-out infinite;
}

.loading-text {
  color: #fff;
  font-size: 1.5rem;
  margin-left: 1rem;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
.div-add-bot {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
}
.div-add-bot--search {
  width: 65%;
  display: flex;
  align-items: center;
}
.div-add-bot--search input {
  margin-right: 10px;
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid #7186a0;
  width: 100%;
}
.bot-chat-log .div-add-bot--search input {
  margin-right: unset;
}
.bm_status-filter {
  display: flex;
  align-items: center;
}
.bm_status-filter > h4 {
  margin: 0 10px 0 0;
  font-size: 16px;
}
.bm_status-filter > #bm_bot-status {
  padding: 5px 12px;
  font-size: 16px;
  border-radius: 10px;
}
.btn-add-bot.btn-bl-search {
  margin-left: 10px;
}
.btn-add-bot {
  border: none;
  color: white;
  font-weight: 600;
  background-color: #51cbce;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  text-transform: uppercase;
}
.btn-edit-bot {
  /* width: 25%; */
  color: #327aed;
  font-weight: 700;
  border: none;
  background: #ffffff;
}
.btn-duplicate-bot {
  /* width: 25%; */
  color: #ff9900;
  font-weight: 700;
  border: none;
  background: #ffffff;
}
.btn-demo-bot {
  /* width: 25%; */
  color: #327aed;
  font-weight: 500;
  border: none;
  background: #ffffff;
}
.btn-stop-bot {
  /* width: 25%; */
  color: #7186a0;
  font-weight: 500;
  border: none;
  background: #ffffff;
}
.btn-delete-bot {
  /* width: 25%; */
  color: #f46835;
  font-weight: 700;
  border: none;
  background: #ffffff;
}

/* table */
table.bl-table {
  text-align: center;
  table-layout: fixed;
  overflow: hidden;
  border: 1px solid;
}
table.bl-table thead tr th {
  border: 1px solid #7186a0;
}

/* action */
.action-wrapper {
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
  justify-content: space-between;
}

.bm__bot-name:hover {
  color: #327aed;
}

.filter-from-to {
  padding: 10px 0;
}
.wapper-search-date {
  width: 100%;
  display: flex;
  align-items: center;
}
.search-date-from {
  margin-right: 30px;
}
.wrap-bot-search {
  display: flex;
}
.label-search-date {
  margin: 0px 5px 0px 0px !important; 
  color: #2c2c2c !important; 
  font-size: 14px !important;
  width: 50px;
}
@media screen and (max-width: 1480px){
  .wrap-bot-search {
    display: block;
  }
  .wapper-search-date { 
    margin-top: 20px;
  }
  .label-search-date {
    width: 70px !important;
  }
  .search-date-from {
    margin-right: 70px;
  }
}