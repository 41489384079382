.field-container {
  width: 70%;
  display: flex;
  align-items: flex-start;
}
.field-lable {
  width: 20%;
  color: #000;
  font-size: 13px;
  text-align: right;
  margin-right: 23px;
  margin-top: 14px;
}
.field-input {
  width: 60%;
  margin-bottom: 15px;
  position: relative;
}

input.ce_input {
  display: block;
  outline: 0;
  border: 1px solid #7186a0;
  border-radius: 3px;
  padding: 10px 18px;
  box-sizing: border-box;
  width: 100%;
}
.field-input-cc {
  width: 60%;
  margin-bottom: 15px;
  border: 1px solid #7186a0;
  border-radius: 3px;
  padding: 10px 18px;
  position: relative;
}
.field-input-cc input {
  border: none;
  padding: 0;
  margin-top: 5px;
}
textarea.ce_textArea {
  width: 100%;
  outline: 0;
  border: 1px solid #7186a0;
  border-radius: 3px;
  padding: 10px 18px;
}
.textarea-email {
  overflow-y: scroll;
  height: 50px;
  width: 100%;
}
.field-btn {
  margin-top: 50px;
  padding-top: 20px;
  border-top: 1px solid #c7d0d9;
  display: flex;
  justify-content: space-between;
}
.err-email-format {
  color: red;
  font-weight: 600;
  display: none;
}
#list-cc,
#list-bcc {
  display: flex;
  flex-wrap: wrap;
  max-height: 60px;
  overflow-y: auto;
}
