h1{
    color:red
}
.translations-content-item{
    background-color: aqua;
}
#test{
    width: 100%;
    height: 100vh;
    border: none;
}