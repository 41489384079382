/* table */
table.file-mng__table {
  text-align: center;
  table-layout: fixed;
  overflow: hidden;
  border: 1px solid;
}

table.file-mng__table thead tr th,
table.file-mng__table td {
  border: 1px solid #7186a0;
}

/* .file-mng__border-table {
    border: 1px solid #7186a0;
} */

.file-mng__action-table {
  width: 250px;
  min-width: 250px;
}

.file-mng__preview {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.file-mng__error {
  color: red;
}

.file-mng__preview img,
.file-mng__preview video {
  width: 80px;
  height: 80px;
  object-fit: contain;
  margin-right: 40px;
  border: 1px solid #b8b8b8;
}

.file-mng__preview-name {
  width: 30%;
  margin-right: 40px;
}

.file-mng__preview-type {
  width: 20%;
  margin-right: 40px;
}

.file-mng__preview .btn {
  height: 50px;
}

/* action */
.file-mng__action-wrapper {
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
  justify-content: space-between;
}

.file-mng__btn-preview {
  /* width: 25%; */
  width: 95px;
  background-color: #1ABC9C;
  font-weight: 700;
  font-size: 12px;
  border: none;
  margin: 5px 2.5px;
  padding: 7.5px 2.5px;
  border-radius: 5px;
  color: #ffffff;
}

.file-mng__btn-copy {
  width: 70px;
  background-color: #5DADE2;
  font-weight: 700;
  font-size: 12px;
  border: none;
  margin: 5px 2.5px;
  padding: 7.5px 10px;
  border-radius: 5px;
  color: #ffffff;
}

.file-mng__btn-delete {
  width: 70px;
  background-color: #f46835;
  font-weight: 700;
  font-size: 12px;
  border: none;
  margin: 5px 2.5px;
  padding: 7.5px 15px;
  border-radius: 5px;
  color: #ffffff;
}

.file-mng__preview_img {
  color: '#51cbce';
  text-align: center;
  height: 500px;
}

.file-mng__preview_img img {
  object-fit: contain;
  width: 100%;
  height: 90%;
  margin: 10px;
}