.report {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 20px;
  align-items: center;
}
.report__info {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  /* width: 75%; */
}
.report__download > .btn {
  margin: 0;
}
.report__group {
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 0;
}
.report__group.report-date {
  max-width: 140px;
}
.report__item {
  margin: 10px 0;
  padding: 10px 0;
  border-top: 1px solid #cfcfcf;
}
.report__item-2 {
  display: flex;
  justify-content: flex-start;
}
.report__item-pie {
  width: 70%;
}
.report__item-2-head {
  width: calc(30% - 10px);
}
.report__item-2-head-main {
  width: calc(100% - 0px);
}
.report__item-2-head:nth-child(2) {
  text-align: center;
}
.report__item-2-head-main:nth-child(2) {
  text-align: center;
}
.report__item-2-head-calender {
  margin: 10px auto;
}
.report__item-head {
  color: #767676;
  font-weight: 700;
}
.report__item-chart {
  height: 400px;
}
.report__item-content {
  max-height: 300px;
  overflow-y: auto;
}

.report__item-content--fix-table {
  width: 100%;
  display: table;
  border-spacing: 0;
  border-collapse: separate;
  overflow: visible;
}
.report__item-content-title {
  top: 0;
  left: 0;
  z-index: 2;
  position: sticky;
  background-color: #fff;
}

.err-date {
  color: red;
  display: block;
}

.report__group-select,
.report__group-input {
  display: block;
  outline: 0;
  border: 1px solid #7186a0;
  border-radius: 3px;
  padding: 7.5px 18px;
  box-sizing: border-box;
  width: 100%;
}
