.ant-modal {
  width: 860px !important;
  height: 700px !important;
  max-height: 800px !important;
  .ant-modal-body {
    max-height: 640px;
    overflow-y: auto !important;
  }
}

.footer-var {
  border-top: 1px solid #aaa;
  padding: 20px 0px;
}

.action_unsub {
  background-color: rgb(243, 156, 18);
}

.action_sub {
  background-color: rgb(155, 89, 182);
}

.action_edit {
  background-color: rgb(26, 188, 156);
}

.action_delete {
  background-color: rgb(231, 76, 60);
}

.ant-table-thead {
  th {
    color: rgb(155, 89, 182) !important;
  }
}

.ant-table-cell:last-child {
  text-align: center;
}

.btn-pm {
  width: 100px;
  padding: 7.5px 15px;
  text-align: center;
  border-radius: 5px;
  border: none;
  color: white;
}

.btn-pm--back {
  @extend .btn-pm;
  background-color: #6c757d;
}

.btn-pm--save,
.btn-pm--add {
  @extend .btn-pm;
  background-color: #52cbce;
}
