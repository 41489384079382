.acc-info__title::before{
    content: "";
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 10px;
    background: #7186a0;
}
.acc-info__heading{
    margin: 16px 0;
}
.acc-info__body{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.acc-info__item{
    width: calc(50% - 10px);
    margin-bottom: 20px;
    border: 1px solid #c7d0d9;
    border-radius: 3px;
    box-shadow: none;
}
.acc-info__item-top{
    padding: 15px;
    border-bottom: 1px solid #c7d0d9;
}
.acc-info__item-head{
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}
.acc-info__item-complete{
    color: #fff;
    font-weight: 700;
    line-height: 25px;
    padding: 0 10px;
    border-radius: 25px;
    background-color: #fda44c;
}
.acc-info__item-desc{
    min-height: 80px;
    padding-top: 10px;
}
.acc-info__item-desc p{
    margin-bottom: 5px;
    font-size: 13px;
}
.acc-info__item-desc span{
    margin-left: 5px;
}
.acc-info__item-desc--pro{
    color: #fff;
    font-weight: 700;
    line-height: 25px;
    padding: 3px 7px;
    border-radius: 5px;
    background-color: #7a51a3;
}
.acc-info__item-desc-pri{
    color: #f46835;
    font-weight: 700;
}
.acc-info__item-desc-pri::before{
    content: "!";
    background-color: #f46835;
    color: #fff;
    width: 19px;
    /* height: 15px; */
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    margin-right: 7px;
}
.acc-info__item-bottom{
    display: flex;
    justify-content: space-between;
    margin: 0 0 0 15px;
}
.acc-info_footer{
    margin-top: 50px;
    padding-top: 20px;
    border-top: 1px solid #c7d0d9;
}
.acc-info_footer-btn{
    margin-bottom: 10px;
    display: block;
    margin-left: auto;
}