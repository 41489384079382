.payment-management-option {
  display: flex;
}
.payment-management-option-item {
  padding: 10px 25px;
  font-size: 18px;
  cursor: pointer;
}
.payment-management-btn-search {
  padding: 10px 25px;
  border: none;
  color: white;
  font-weight: 600;
  border-radius: 10px;
  background-color: #57c8f0;
}

/* setting */
.payment_management_setting__item {
  padding: 40px 0;
}
.payment_management_setting__item h6 {
  color: #767676;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ccc;
}
.payment_management_setting__check input,
.payment_management_setting__check select {
  width: auto;
  display: inline-block;
  margin-right: 10px;
  padding: 7px 18px;
}

.payment_management_setting__check label {
  margin-right: 20px;
  margin-bottom: 0;
  margin-bottom: 15px;
}
.payment_management_setting__check-in > p {
  color: #767676;
  font-size: 14px;
  margin-bottom: 4px;
}
.payment_management_setting__check-out select {
  width: 80px;
  display: inline-block;
  margin-bottom: 10px;
}
.payment_management_setting__check-out > p,
.payment_management_setting__can > p {
  display: inline-block;
  color: #767676;
  font-size: 14px;
  margin-right: 30px;
  margin-bottom: 4px;
}
.payment_management_setting__can select {
  width: 200px;
  display: inline-block;
  margin-bottom: 10px;
}

.payment-management-datepicker {
  display: block;
  outline: 0;
  border: 1px solid #7186a0;
  border-radius: 3px;
  padding: 10px 18px;
  box-sizing: border-box;
  width: 100%;
}
.span_end {
  width: 2%;
  padding-top: 5px;
  text-align: center;
  font-size: 15px;
}

/* Payment-Gateway-CSS */
.payment-gatway-btn-add-gateway {
  padding: 10px 70px;
  background-color: #57c8f1;
  color: white;
  border: none;
  border-radius: 10px;
}
.payment-gateway-btn-edit {
  /* margin-right: 15px; */
  width: 70px;
  border: none;
  background-color: #57c8f0;
  border-radius: 5px;
  color: white;
  padding: 10px 10px;
}
.payment-gateway-btn-delete {
  margin-left: 15px;
  width: 70px;
  border: none;
  background-color: #f48564;
  border-radius: 5px;
  color: white;
  padding: 10px 10px;
}
.payment-gateway-btn-default {
  margin-right: 15px;
  width: 100px;
  border: none;
  background-color: #1890ff;
  border-radius: 5px;
  color: white;
  padding: 10px 10px;
}
.payment-gateway-cnf-btn {
  display: flex;
  text-align: center;
  margin: auto;
}
.payment-gateway-cnf-btn-detail-yes {
  margin: auto;
  border: none;
  padding: 7.5px 35px;
  border-radius: 5px;
  background-color: #57c8f0;
  font-weight: 500;
}
.payment-gateway-cnf-btn-detail-no {
  margin: auto;
  border: none;
  padding: 7.5px 35px;
  border-radius: 5px;
  font-weight: 500;
}

/* Add-Payment-Gateway-CSS */
.add-payment-gateway-add-form {
  width: 80%;
  margin: 2% 10%;
  display: flex;
}
.add-payment-gateway-span-form {
  width: 20%;
  text-align: right;
  margin-right: 2.5%;
}
.add-payment-gateway-input-form {
  width: 50%;
  text-align: left;
  height: 40px;
}
.add-payment-gateway-btn-return {
  padding: 10px 30px;
  color: white;
  background-color: #c7cbd6;
  border: none;
  width: 120px;
  margin-right: 10px;
  border-radius: 5px;
}
.add-payment-gateway-btn-save {
  padding: 10px 30px;
  color: white;
  background-color: #57c8f0;
  border: none;
  width: 120px;
  margin-right: 10px;
  border-radius: 5px;
}
.payment-management-datePicker input {
  border: 1px solid grey;
  border-radius: 5px;
  padding: 5px 10px;
}

/* authorization */
.pm-select {
  border: 1px solid grey;
  border-radius: 5px;
  padding: 5px 10px;
  margin-left: 10px;
}
/* table */
table.payment-gateway__table {
  text-align: center;
  table-layout: fixed;
  overflow: hidden;
  border: 1px solid;
}
table.payment-gateway__table thead tr th,
table.payment-gateway__table td {
  border: 1px solid #7186a0;
}
