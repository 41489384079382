.left-column {
  flex-basis: calc(3 / 12 * 100%);
  padding-right: 5px;
}
.right-column {
  flex-basis: calc(9 / 12 * 100%);
  padding: 10px 10px 10px 10px;
  background-color: #f1f2f7;
  overflow-y: auto;
  min-width: 750px;
}

.button:hover {
  background-color: #f2f2f2;
}

button:hover .info {
  display: inline-block;
  color: gray;
  margin-right: 5px;
}
.info {
  display: none;
}

.button {
  border: none;
  background-color: white;
  text-align: justify;
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}

.date-picker {
  display: inline-block;
  width: max-content !important;
}
.div.react-datepicker-wrapper {
  display: inline-block;
  width: max-content !important;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #919191;
  border-radius: 5px;
}

.csp-body {
  padding: 10px;
  height: 100%;
  min-width: 700px !important;
}
.csp-body-bot-side {
  display: flex;
  width: calc(700px * 0.7);
  justify-content: flex-start;
}
.csp-body-user-side-messages {
  width: calc(700px * 0.7);
  background-color: #fff;
  padding: 10px;
  border-radius: 20px;
  justify-self: flex-end;
  margin-right: calc(100% - 700px);
}
@media screen and (max-width: 750px) {
  .sp-body-user-side-messages {
    margin-right: 0;
  }
}
