.add-bot-container {
  display: flex;
  width: 100%;
  box-sizing: border-box;
}
.bot-left {
  width: 53%;
  /* height: 500px; */
  border-right: 1px solid #ccc;
}
.bot-right {
  flex: 1;
  /* height: 500px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* field add bot */
.field-add-bot {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
}
.add-bot_field-container {
  display: flex;
  align-items: center;
}
.label-field {
  width: 30%;
}
.input-field {
  flex: 1;
  border: 1px solid black;
  border-radius: 5px;
  padding: 12px 8px;
}
.subtitle-field {
  display: inline-block;
  margin-top: 4px;
  color: rgba(0, 0, 0, 0.87);
  font-size: 12px;
  text-align: right;
}

/* design types */
.design-types {
  display: flex;
  align-items: center;
}
.design-types .type {
  position: relative;
  width: 90px;
  height: 90px;
  border-radius: 5px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 2px dashed #ccc;
  margin-right: 14px;
}
.design-types .type:last-child {
  margin: 0;
}
.design-types .type.active {
  border-color: blue;
  border-style: solid;
  background-color: azure;
}

/* main colors */
.main-colors {
  display: flex;
  align-items: center;
}
.main-colors .color {
  cursor: pointer;
  width: 50px;
  height: 50px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.main-colors .color:hover {
  border: 1px dashed #ccc;
}
.main-colors .color.active {
  border: 1px solid blue;
  background-color: azure;
}
.main-colors .color span {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: block;
}

/* button wrapper */
.btn-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px;
}
.btn.btn-preview {
  color: rgb(51, 45, 45);
  background-color: #51c4fd;
}
.btn.btn-close {
  color: rgb(51, 45, 45);
  background-color: white;
  border: 1px solid #51c4fd;
  margin-right: 15px;
}
.btn.btn-new-bot {
  color: rgb(51, 45, 45);
  background-color: #51c4fd;
}

/* icons */
.icons {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex: 1;
}
.icons .icon {
  margin-right: 14px;
  margin-bottom: 14px;
}
.icons .icon img {
  display: block;
  width: 54px;
  height: 54px;
  border-radius: 50%;
  cursor: pointer;
}
.icons .icon.active img {
  border: 1px solid blue;
}
.add-icon {
  width: 54px;
  height: 54px;
  border-radius: 50%;
  background-color: #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
}
.add-icon:hover {
  background-color: #ccc;
}
.add-icon > span {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  background-color: #fff;
  font-size: 44px;
  color: #ccc;
  cursor: pointer;
}

/* image show */
.image-show {
  display: flex;
  justify-content: center;
  max-height: 200px;
}

.image-show img {
  object-fit: contain;
}

/* error message */
.error-message {
  display: inline-block;
  margin-top: 4px;
  font-size: 12px;
  text-align: center;
  color: red;
}

/* bot image */
#bot_image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
