/* table */
table.rm-table {
  text-align: center;
  table-layout: fixed;
  overflow: hidden;
  border: 1px solid;
}
table.rm-table thead tr th,
table.rm-table td {
  border: 1px solid #7186a0;
}

/* input */
.rm-input-field {
  padding: 5px 10px;
  border-radius: 5px;
  border: solid 1px #7186a0;
}

#rm-list-client {
  padding: 5px 10px;
  border-radius: 5px;
  border: solid 1px #7186a0;
}

.rm-add-mail-wrapper {
  display: flex;
  align-items: center;
}
.rm-add-mail-wrapper > select {
  margin-right: 10px;
}

.rm-add-mail-popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  height: auto;
  padding: 25px 15px;
  background-color: antiquewhite;
  border-radius: 5px;
  box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.4);
  z-index: 110;
}
.rm-add-mail__title {
  font-weight: 400;
  text-align: center;
}
#rm-add-mail__form {
}
.rm-add-mail-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  position: relative;
}
.rm-add-mail-item > label {
  min-width: 100px;
}
.rm-add-mail-item > input {
  flex: 1;
}
#rm-input-checkbox {
  position: absolute;
  right: 10px;
  z-index: 1;
}
.rm-modal {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 105;
}
