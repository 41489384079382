.btn {
  margin-right: 15px;
}
.var-form-input {
  display: block;
  outline: 0;
  border: 1px solid #7186a0;
  border-radius: 3px;
  padding: 10px 18px;
  box-sizing: border-box;
  width: 100%;
}
.var-variable-note {
  margin: 20px 0;
}
.var-variable-search {
  display: flex;
  width: 61.5%;
}
.var-variable-search button {
  margin: 0 0 0 10px;
}
.var-form__head {
  display: flex;
}
.var-form__head label:first-child {
  width: 30%;
  margin-right: 1.5%;
}
.var-form__head label:last-child {
  width: 50%;
}
.var-form__variable-group {
  display: flex;
  align-items: flex-start;
}
.var-form__variable-name {
  width: 30%;
  margin-right: 1.5%;
  margin-bottom: 10px;
}
.var-form__variable-name input {
  border: 1px solid #7186a0;
  border-radius: 3px;
  padding: 10px 1.5%;
  background-color: #e1e4e8;
}
.var_defined-variable .var-form__variable-value {
  width: 50%;
  margin-right: 1.5%;
  border: 1px solid #7186a0;
  border-radius: 3px;
  padding: 10px 1.5%;
}
.var-none-border {
  border: none;
  padding-left: 0;
}
.var-form__variable-delete {
  margin-top: 10px;
}
.var-div-add-new {
  width: 76%;
  display: flex;
  margin-top: 10px;
}
.var-btn-add-new {
  /* font-size: 20px; */
  margin-left: 83%;
  font-weight: 700;
  color: #ffffff;
  border: none;
  padding: 5px 10px 5px 10px;
  border-radius: 5px;
  background-color: #51cbce;
}
.var_system-variable .var-form__variable-group {
  align-items: center;
}
.var_system-variable .var-form__variable-value {
  width: 70%;
}
.err-varriable {
  color: red;
  display: block;
}
