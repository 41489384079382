input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.bs-field-container {
  width: 70%;
  display: flex;
  align-items: flex-start;
}
.bs-field-lable {
  display: inline-block;
  width: 20%;
  color: #000;
  font-size: 13px;
  text-align: right;
  margin-right: 23px;
  margin-top: 14px;
}
.bs-field-lable:first-child {
  text-transform: capitalize;
}
.bs-field-input {
  width: 60%;
  margin-bottom: 15px;
  position: relative;
}

.bs-field-input-item,
.bs-field-input-select {
  display: block;
  outline: 0;
  border: 1px solid #7186a0;
  border-radius: 3px;
  padding: 10px 18px;
  box-sizing: border-box;
  width: 100%;
}
.bs-field-btn {
  margin-top: 50px;
  padding-top: 20px;
  border-top: 1px solid #c7d0d9;
  display: flex;
  justify-content: flex-end;
}
.bs-err-format {
  color: red;
}
.bs-err-format:first-child {
  text-transform: capitalize;
}
