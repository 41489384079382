@import url('https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800');
* {
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
}
body {
  background: #ffffff;
  min-height: 100vh;
  display: flex;
  font-weight: 400;
  font-family: 'Fira Sans', sans-serif;
}
h1,
h2,
h3,
h4,
h5,
h6,
label,
span {
  font-weight: 500;
  font-family: 'Fira Sans', sans-serif;
}
body,
html,
.App,
#root,
.auth-wrapper {
  width: 100%;
  height: 100%;
}
.navbar-light {
  background-color: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
}
.auth-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
  margin-top: 5%;
}
.auth-inner {
  width: 450px;
  margin: auto;
  background: #f4f3f0;
  box-shadow: 10px 14px 80px rgba(19, 21, 46, 0.2);
  padding: 40px 55px 45px 55px;
  border-radius: 15px;
  transition: all 0.3s;
}
.auth-wrapper .form-control:focus {
  border-color: #167bff;
  box-shadow: none;
}
.auth-wrapper h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}
.custom-control-label {
  font-weight: 400;
}
.forgot-password,
.forgot-password a {
  text-align: right;
  font-size: 13px;
  padding-top: 10px;
  color: #000;
  margin: 0;
}
.forgot-password a {
  color: #000;
}

.custom-label:hover {
  background-color: green !important;
}

#addLabelItem:hover {
  cursor: pointer;
  background-color: green !important;
}

#AddTableButton:hover > button {
  color: white !important;
  background-color: green !important;
}

.nc-icon.nc-simple-remove:hover {
  background-color: rgb(81, 203, 205) !important;
  color: white !important;
}

#btnEditDetail > button:hover {
  background-color: rgb(81, 203, 205) !important;
}

.loading-animation {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 10px solid white;
  border-top-color: rgb(81, 203, 205);
  animation: loading infinite 0.5s;
}

@keyframes loading {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
