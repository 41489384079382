.tab-menu{
    border: none;
    width: 150px;
    height: 35px;
    padding: 0;
    border: 0;
    box-sizing: content-box;
    font-size: small;
}

.var_defined-variable .var-form__variable-value {
    width: 50%;
    margin-right: 1.5%;
    border: 1px solid #7186a0;
    border-radius: 3px;
    align-items: center;
    display: flex;
    padding: 10px 1.5%;
  }

  .body {
    display: flex;
    width: 100%;
   height: 100%;
  }
.bot-haft {
    width: 100%;
}
.ss-user-setting__item-bottom-flex-start {
  display: flex;
  align-items:flex-start;
  justify-content: left;
  height: 40px;
  width: 50%;
  margin: 0px;
}
.ss-user-setting-input-delay {
  flex: 1;

}
.ss-user-setting-input-limit-character {
  width: 20%;
  padding: 3px 0px 0px;
  height: 36px;
}
  .input-setting2{
    padding: 0 10px;
    height: 40px;
    border-radius: 5px;
    width: 45%;
    border: 1px solid #333;
    text-align: left;

  }
  .input-setting{
    padding: 0 10px;
    height: 40px;
    border-radius: 5px;
    width: 100%;
    border: 1px solid #333;
  }